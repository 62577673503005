function Footer() {
    return (
        <div className="col-12 footer mt-3" style={{ position: 'sticky'}}>
            <div className="card-footer container">
               
                <div className="row">
                    <div className="col-6 mt-2">
                        Copyright © Your Website 2021
                    </div>
                    <div className="col-6 mt-2">
                        <ul className="list float-end">
                            <li className="list-group-item"><a href="Privacy-Policy">Privacy Policy</a></li>|
                            <li className="list-group-item"><a href="Terms-Conditions">Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Footer;