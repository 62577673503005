import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import Font Awesome icons

const ChangePassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [newPasswordVisible, setNewPasswordVisible] = useState(false); // State for new password visibility
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // State for confirm password visibility

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let mobile = sessionStorage.getItem('mobile');
        let password = sessionStorage.getItem('password');
        if (newPassword !== confirmPassword) {
            setErrorMessage('New Password & Confirm Password do not match');
        } else {
            setErrorMessage('');
            // Proceed with form submission logic (e.g., send to server)
            fetch('https://api.sattakingvip.co.in/changePassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mobile: mobile,
                    password: password,
                    newPassword: newPassword
                }),
            }).catch(error => console.error(error));

            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('market_name');
            sessionStorage.removeItem('mobile');
            sessionStorage.removeItem('password');
            window.location.reload();
        }
    };

    return (
        <div className="p-3" style={{ width: '100%' }}>
            <div className='col-12 changePassword text-white'>
                <div className='card bg-primary text-white'>
                    <div className='card-header text-center p-3'><h3>Change Password</h3></div>
                    <div className='card-body bg-primary'>
                        <form onSubmit={handleSubmit}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className="mb-3 mt-3">
                                        <label htmlFor="newPassword" className="form-label">New Password</label>
                                        <div className="input-group">
                                            <input
                                                type={newPasswordVisible ? 'text' : 'password'}
                                                className="form-control"
                                                id="newPassword"
                                                value={newPassword}
                                                required
                                                onChange={handleNewPasswordChange}
                                            />
                                            <button
                                                type="button"
                                                className="btn bg-white"
                                                onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                                            >
                                                {newPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                        <div className="input-group">
                                            <input
                                                type={confirmPasswordVisible ? 'text' : 'password'}
                                                className="form-control"
                                                id="confirmPassword"
                                                value={confirmPassword}
                                                required
                                                onChange={handleConfirmPasswordChange}
                                            />
                                            <button
                                                type="button"
                                                className="btn bg-white"
                                                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                            >
                                                {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                            </button>
                                        </div>
                                    </div>
                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                    <div className="mb-3 mt-3">
                                        <button type='submit' className='col-md-12 btn btn-secondary'>SUBMIT</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='card-footer p-3'></div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
