import { useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const momenttz = require('moment-timezone');


function AllVisitorNewVisitor({ allVisitor, newVisitor }) {
    // var currentDate = moment().tz('Asia/Kolkata').format("YYYY-MM-DD");
    console.log('allVisitor', allVisitor.length);
    console.log('newVisitor', newVisitor.length);

    const [activeTab, setActiveTab] = useState('All Visitor');
    const [bannerName, setBannerName] = useState('Visitors');
    const [visitorLength, setVisitorLength] = useState(allVisitor?.length);
    const [visitorPage, setVisitorPage] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState([10]);

    const handleCountChange = (event) => {
        setItemPerPage(event.target.value)
    };
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setBannerName(tab);
        setCurrentPage(1); // Reset to first page when tab changes
        if (tab == 'All Visitor') {
            setVisitorLength(allVisitor.length)
            setVisitorPage(Math.ceil(allVisitor.length / itemsPerPage))
        } else {
            setVisitorLength(newVisitor.length)
            setVisitorPage(Math.ceil(newVisitor.length / itemsPerPage))
        }
    };

    // pagination setting
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // divinding to show per page
    const paginatedAllVisitor = allVisitor.slice(indexOfFirstItem, indexOfLastItem);
    const paginatedNewVisitor = newVisitor.slice(indexOfFirstItem, indexOfLastItem);




    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginationControls = (totalPages) => (
        <div className="col-12">
            <div className="row d-flex justify-content-between">

                <button className="btn btn-primary float-start col-md-3 col-sm-3" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    {'< Previous'}
                </button>

                {/* <div className="col-md-6 text-center">
                    <span>show per page <select name='perPage' onChange={handleCountChange} className="form-control col-md-2">
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='30'>30</option>
                        <option value='40'>40</option>
                    </select></span>

                </div> */}
                <button className="btn btn-primary float-end col-md-3 col-sm-3" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} >
                    {'Next >'}
                </button>
            </div>
        </div>
    );

    return (
        <div className="card mt-5 visitor">
            <div className="card-header">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <span className="badge bg-dark position-relative">
                                <FontAwesomeIcon className='fonticon' icon="table" />{bannerName}
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    {visitorLength}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="visitorTable">
                <ul className="nav nav-tabs card-header">
                    <li className="nav-item">
                        <button className={`nav-link ${activeTab === 'All Visitor' ? 'active' : ''}`} onClick={() => handleTabClick('All Visitor')}>
                            All Visitor
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${activeTab === 'New Visitor' ? 'active' : ''}`} onClick={() => handleTabClick('New Visitor')} >
                            New Visitor
                        </button>
                    </li>
                </ul>
                <div className="tab-content">
                    {activeTab === 'All Visitor' && (
                        <div className="tab-pane active">
                            <div className='video table-responsive' id="scrollbar1">
                                <table className="table text-center">
                                    <thead>
                                        <tr>
                                            <th>S.NO.</th>
                                            <th>Site Name</th>
                                            <th>Visit Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedAllVisitor.map((allVisit, index) => (
                                            <tr key={allVisit.id}>
                                                <td>{index + 1 + indexOfFirstItem}</td>
                                                <td className="text-start">
                                                    <a href={`https://${allVisit.site}`} target="_blank" rel="noopener noreferrer">{allVisit.site}</a>
                                                </td>
                                                <td>{moment(allVisit.visit_date).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    )}
                    {activeTab === 'New Visitor' && (
                        <div className="tab-pane active">
                            <div className='video table-responsive' id="scrollbar1">
                                <table className="table text-center">
                                    <thead>
                                        <tr>
                                            <th>S.NO.</th>
                                            <th>Site Name</th>
                                            <th>Visit Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedNewVisitor.map((newVisit, index) => (
                                            <tr key={newVisit.id}>
                                                <td>{index + 1 + indexOfFirstItem}</td>
                                                <td className="text-start">
                                                    <a href={`https://${newVisit.site}`} target="_blank" rel="noopener noreferrer">{newVisit.site}</a>
                                                </td>
                                                <td>{moment(newVisit.visit_date).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    )}
                </div>
                <div className="card-footer">
                    {(visitorLength) && (
                        <>
                            {paginationControls(visitorPage)}
                        </>
                    )}


                </div>
            </div>
        </div>
    );
};

export default AllVisitorNewVisitor;
