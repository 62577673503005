import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bgmLogo from '../images/bgm_logo.png'
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import Font Awesome icons

const LoginForm = ({ setIsAuthenticated, setUserRole }) => {
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        mobile: '',
        password: '',
        remember: false,
    });
    const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://api.sattakingvip.co.in/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const json = await response.json();

            if (json !== 'retry') {
                setIsAuthenticated(true);
                sessionStorage.setItem('authToken', json.isAdmin);
                sessionStorage.setItem('market_name', json.market_name);
                sessionStorage.setItem('mobile', formData.mobile);

                toast.success('Login successful!', { autoClose: 3000 });

                if (json.isAdmin === '1') {
                    setUserRole('admin');
                    await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 3 seconds before navigating
                    navigate("/admin");
                } else if (json.isAdmin === '2') {
                    setUserRole('subadmin');
                    await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 3 seconds before navigating
                    navigate("/admin/games");
                }
            } else {
                setIsAuthenticated(false);
                toast.error('Invalid credentials', { autoClose: 2000 });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="loginmain p-4 vh-100">
            <ToastContainer />
            <div className='col-12 login'>

                <div className='card'>
                    <div className='card-header text-center p-3'>
                        <h3 className='text-center'>LOG IN</h3>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="mobile"
                                    name="mobile"
                                    placeholder="Mobile no"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="password">Password</label>
                                {/* <div className="input-group">
                                    <input
                                        type={passwordVisible ? 'text' : 'password'}
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                    >
                                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div> */}

                                <div className="input-group">
                                    <input
                                        type={passwordVisible ? 'text' : 'password'}
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        className="btn bg-white"
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                    >
                                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>


                            </div>
                            <div className="form-check mt-3">
                                <label className="form-check-label" htmlFor="remember">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="remember"
                                        name="remember"
                                        checked={formData.remember}
                                        onChange={handleChange}
                                    />
                                    Remember password
                                </label>
                            </div>

                        </form>
                    </div>
                    <div className='card-footer p-3'>
                        <button type="submit" className="btn btn-primary col-12" onClick={handleSubmit}>
                            Login
                        </button>
                    </div>
                </div>

                <div className='text-center loginlogo'>
                    <img src={bgmLogo} className='text-center' />
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
