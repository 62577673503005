
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTable, faTrashAlt, faEdit, faEye, faToggleOn, faToggleOff, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, json, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, createContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
library.add(faTable);
function Vendor() {
    const location = useLocation(); // Use the useLocation hook
    const isEditPage = location.pathname.includes('/admin/vendor/edit');
    const isAddPage = location.pathname.includes('/admin/vendor/add');
    const isViewPage = location.pathname.includes('/admin/vendor/view');
    const [userDetail, userUpdate] = useState([]);
    // add, edit delet view gussing
    const [description, setDescription] = useState('Add Description');
    const [formData, setFormData] = useState({});
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    // Function to handle the toggle

    const handleRowClick = (row) => {
        setFormData(row);
    };

    const handleDelete = (id) => {

        const deleteData = userDetail.find(item => item.id == id)
        fetch('https://api.sattakingvip.co.in/userData', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...deleteData, isDeleted: true }),
        })
            .then(response => response.json())
            .then(json => {
                userUpdate(json)

            })
            .catch(error => console.error(error));
        const updatedData = userDetail.filter(item => item.id !== id);
        userUpdate(updatedData);
    };


    
   
    useEffect(() => {
        const currentUrl = window.location;
        if (currentUrl.pathname.includes('/admin/vendor/edit')) {
            setIsFormDisabled(false);
            setDescription('Edit Description');
        } else if (currentUrl.pathname.includes('/admin/vendor/view')) {
            setIsFormDisabled(true);
            setDescription('View Description');
        } else {
            setIsFormDisabled(false);
            setFormData({})
            setDescription('Add Description');
        }
    }, [location.pathname]);

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };


    const [sendList, sndList] = useState([]);
    useEffect(() => {

        fetch('https://api.sattakingvip.co.in/getGameName', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(json => {
                sndList(json)

            })
            .catch(error => console.error(error));
        fetch('https://api.sattakingvip.co.in/userData', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(json => {
                userUpdate(json)

            })
            .catch(error => console.error(error));
    }, []);
    

    // const handleChange = (event) => {
    //     const { name, value } = event.target;
    //     setFormData({
    //         ...formData,
    //         description: content
    //     });
    // };

    // Handle input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        for (let index = 0; index < userDetail.length; index++) {
            const element = userDetail[index];
            if (isAddPage) {

                if (element.mobile == value) {
                    return toast.error("Mobile number already Exist");
                }
            } else {

            }

        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
       
        let isAdd = false
        let isEdit = false
        if (isAddPage) {
            isAdd = true
        }
        if (isEditPage) {
            isEdit = true
        }
        fetch('https://api.sattakingvip.co.in/userData', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...formData, addPage: isAdd, editPage: isEdit }),
        })
            .then(response => response.json())
            .then(json => {
                userUpdate(json)

            })
            .catch(error => console.error(error));
        
        navigate(-1);

    };

    const [toggleStates, setToggleStates] = useState(userDetail.reduce((acc, row) => ({ ...acc, [row.id]: false }), {}));
    const handleToggle = (id) => {
        setToggleStates((prevStates) => ({ ...prevStates, [id]: !prevStates[id] }));
    };
    return (
        <div className="card mt-5">
            {
                (!(isEditPage || isViewPage || isAddPage)) &&
                (
                    <div>
                        <div className="card-header">
                            <div className="col-12">
                                <div className="row d-flex">
                                    <div className="col-6">
                                        <span className="badge bg-dark"><FontAwesomeIcon className='fonticon' icon="table" />Vendor</span>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-primary float-end">
                                            <Link to="/admin/vendor/add" className='nav-link'>Add Vendor</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='gussing table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col-1">S.NO.</th>
                                        <th scope="col-2">Name</th>
                                        <th scope="col-2">Mobile No</th>
                                        <th scope="col-5">Password</th>
                                        <th scope="col-5">User Type</th>
                                        <th scope="col-2">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userDetail?.map((row, index) => (
                                        <tr key={row.id}>
                                            <td>{index + 1}</td>
                                            <td>{row.name}</td>
                                            <td>{row.mobile}</td>
                                            <td>{row.password}</td>
                                            <td>{row.isAdmin=='1'?'Admin':'Subadmin'}</td>
                                            <td className=''>
                                                {/* <FontAwesomeIcon key={row.id} className='form-switch-on' id={row.id} icon={toggleStates[row.id] ? faToggleOff : faToggleOn} size="1x" onClick={() => handleToggle(row.id)} style={{ cursor: 'pointer' }} /> */}
                                                <FontAwesomeIcon icon="trash-alt" style={{ cursor: 'pointer' }} className='form-switch ' onClick={() => handleDelete(row.id)} />
                                                <Link to="/admin/vendor/edit" className='nav-link form-switch'><FontAwesomeIcon size="1x" icon="edit" onClick={() => handleRowClick(row)} /></Link>
                                                <Link to="/admin/vendor/view" className='nav-link form-switch'><FontAwesomeIcon size="1x" icon="eye" onClick={() => handleRowClick(row)} /></Link>
                                            </td>
                                            {/* <td>{row.id}</td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

            {/* <AddGussing message={selectedRow} /> */}

            {
                (isEditPage || isViewPage || isAddPage)
                && (
                    <div className='addGussing' style={{ display: 'block' }}>
                        <div className="">
                            {/* {row} */}
                            <div className="card-header">
                                <div className="col-12">
                                    <div className="row d-flex">
                                        <div className="col-6">
                                            <span className="badge bg-dark">{description}</span>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-dark float-end" onClick={handleBackClick}>
                                                <FontAwesomeIcon icon="arrow-left" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="p-3">
                                <form id="xyz" className="align-items-center" onSubmit={handleSubmit}>

                                    <div className="form-group">
                                        <label className="form-label">Name</label>
                                        <input type="text" name="name" value={formData?.name} onChange={handleInputChange} className="form-control" required />
                                    </div>
                                    <div className="form-group pt-4">
                                        <label className="form-label">Mobile Number</label>
                                        <input type="number" name="mobile" value={formData?.mobile} onChange={handleInputChange} className="form-control" required />
                                    </div>
                                    <ToastContainer
                                        autoClose={2000}
                                        position="top-right"
                                        hideProgressBar={false}
                                        newestOnTop={true}
                                        closeOnClick
                                        pauseOnHover
                                        draggable={true} />
                                    <div className="form-group">
                                        <label className="form-label">Password</label>
                                        <input type="password" name="password" value={formData?.password} onChange={handleInputChange} className="form-control" required />
                                    </div>
                                    
                                    <div className="form-group pt-4">
                                        <label className="form-label">Game Name</label>

                                        <select
                                            name="market_name"
                                            onChange={handleInputChange}
                                            value={formData?.market_name}
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="dropdown"
                                            required={formData.isAdmin!=1 ? true : false}
                                            disabled={isFormDisabled} // replace this with your `isFormDisabled` state
                                        >
                                            <option></option>
                                            {sendList?.map((game) => (
                                                <option name='gameName' key={game.id} value={game.value}>
                                                    {game.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group pt-4">
                                        <label className="form-label">User Type</label>

                                        <select
                                            name="isAdmin"
                                            onChange={handleInputChange}
                                            value={formData?.isAdmin}
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="dropdown2"
                                            required
                                            disabled={isFormDisabled} // replace this with your `isFormDisabled` state
                                        >
                                            <option></option>
                                                <option name='admin' key='1' value='1'>admin</option>
                                                <option name='subadmin' key='2' value='2'>subadmin</option>
                                        </select>
                                    </div>
                                    {
                                        !(isViewPage) && (
                                            <div className="form-group pt-4" style={{ display: 'block' }}>
                                                <button type="submit" className="btn btn-info" >Submit</button>
                                            </div>
                                        )

                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )


}

export default Vendor;