import axios from 'axios';

const getVisitorStats = (siteName, date) => {
    const params = {};

    return axios.get('https://api.sattakingvip.co.in/getVisitorStats', {})
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching visitor stats:', error);
            throw error;
        });
};

export default getVisitorStats;