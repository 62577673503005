
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, json, useNavigate } from 'react-router-dom';
import DeleteConfirmaModal from './deletModalBox';


function Games() {
    const location = useLocation();
    const [gameList, sendGameList] = useState([]);
    const [formData, setFormData] = useState({});
    const [isDisable, setDisabled] = useState(false);
    const [isNotDisable, setNotDisabled] = useState('');
    const [description, setDescription] = useState('Add Games');
    const [showModal, setShowModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const isAddPage = location.pathname.includes('/admin/games/add');
    const isAddResult = location.pathname.includes('/admin/games/addResult');
    const isEditPage = location.pathname.includes('/admin/games/edit');

    useEffect(() => {
        if (isEditPage) {
            setDescription('Edit Result');
        } else if (isAddResult) {
            setDescription('Add Result');
        } else {
            setDescription('Add Games');
            setFormData({})
            return
        }
    }, [location.pathname]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    useEffect(() => {
        const token = sessionStorage.getItem('authToken');
        const gamename = sessionStorage.getItem('market_name');
        if (token == '2') {
            setDisabled(true)
            setNotDisabled(gamename)
        }
        fetch('https://api.sattakingvip.co.in/gameData', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(json => {
                sendGameList(json)
            })
            .catch(error => console.error(error));
    }, []);


    const handleRowClick = (row) => {
        setFormData(row);
    };

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    const addGameSubmit = ((event) => {
        // return
        event.preventDefault();

        fetch('https://api.sattakingvip.co.in/gameData', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...formData, editPage: isEditPage }),
        })
            .then(response => response.json())
            .then(json => {
                sendGameList(json)

            })
            .catch(error => console.error(error));
        handleBackClick()

    });


    const handleDelete = (id) => {
        setCurrentId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentId(null);
    };
    const handleConfirmDelete = () => {
        const id = currentId;
        // const deleteData = videoDetail.find(item => item.id === id);



        // const handleDelete = (id) => {
        //     const isConfirmed = window.confirm('Are you sure you want to delete?');
        //     if (isConfirmed) {
        //         alert('deleted');
        const deleteData = gameList.find(item => item.id == id)
        const updatedData = gameList.filter(item => item.id !== id);
        sendGameList(updatedData);
        handleCloseModal();
        // } else {
        //     return
        // }
    };

    const addResultSubmit = ((event) => {
        event.preventDefault();
        fetch('https://api.sattakingvip.co.in/resultUpdate', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...formData, isAddResult: isAddResult }),
        }).catch(error => console.error(error));

        handleBackClick()
    });


    return (
        <div className="card mt-5">

            {(!(isEditPage || isAddPage)) &&
                (
                    <div>
                        <div className="card-header">
                            <div className="col-12">
                                <div className="row d-flex" >
                                    <div className="col-6">
                                        {/* <span className="badge bg-dark"><FontAwesomeIcon className='fonticon' icon="table" />Games</span> */}
                                        <span className="badge bg-dark position-relative">
                                            <FontAwesomeIcon className='fonticon' icon="table" />Games
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {gameList.length}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-6">
                                        <Link to="/admin/games/add" className='nav-link' disabled>
                                            <button className="btn btn-primary float-end" disabled>
                                                Add Games
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='games table-responsive' id="scrollbar1">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col-2">S.NO.</th>
                                        <th scope="col-3">Game Name</th>
                                        <th scope="col-3">Result Time</th>
                                        <th scope="col-3">Action</th>
                                        <th scope="col-1"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gameList.map((gameData, index) => (

                                        <tr key={gameData.id}>
                                            <td>{index + 1}</td>
                                            <td>{gameData.market_name}</td>
                                            <td>{gameData.market_sunday_time_open}</td>
                                            <td className='' disabled={isDisable && isNotDisable != gameData.market_name}>
                                                {isDisable && isNotDisable != gameData.market_name ? (<FontAwesomeIcon icon="trash-alt" style={{ cursor: 'pointer' }} className={isDisable ? 'form-switch disabled' : 'form-switch'} />) : (
                                                    <FontAwesomeIcon icon="trash-alt" style={{ cursor: 'pointer' }} className='form-switch' onClick={() => handleDelete(gameData.id)} />
                                                )}

                                                {isDisable && isNotDisable != gameData.market_name ? (<FontAwesomeIcon size="1x" className='form-switch disabled' icon="edit" />) : (<Link to="/admin/games/edit" className='nav-link form-switch' disabled={isDisable}><FontAwesomeIcon size="1x" icon="edit" onClick={() => handleRowClick(gameData)} /></Link>)}
                                            </td>
                                            <td className=''>
                                                <Link to="/admin/games/addResult" className='nav-link' onClick={() => handleRowClick(gameData)} >
                                                    <button className={isDisable && isNotDisable != gameData.market_name ? 'form-switch disabled btn-secondary btn float-end' : 'btn btn-primary float-end'} disabled={isDisable && isNotDisable != gameData.market_name} >
                                                        Add Result
                                                    </button>
                                                </Link>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                                <DeleteConfirmaModal
                                    show={showModal}
                                    handleClose={handleCloseModal}
                                    handleConfirm={handleConfirmDelete}
                                />
                            </table>
                        </div>
                    </div>
                )
            }

            {
                (isEditPage || isAddPage && !isAddResult)
                && (

                    <div className='addGames' style={{ display: 'block' }}>
                        <div className="">
                            {/* {row} */}
                            <div className="card-header">
                                <div className="col-12">
                                    <div className="row d-flex">
                                        <div className="col-6">
                                            <span className="badge bg-dark">{description}</span>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-dark float-end" onClick={handleBackClick}>
                                                <FontAwesomeIcon icon="arrow-left" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="p-3">
                                <form onSubmit={addGameSubmit}>
                                    <div class="form-group row">
                                        <label for="marketName" class="col-sm-4 col-form-label">Market Name</label>
                                        <div class="col-sm-8">
                                            <input type="text" name="market_name" readonly class="form-control" value={formData.market_name} onChange={handleInputChange} required disabled />
                                        </div>
                                    </div>
                                    <div class="form-group row pt-3">
                                        <label for="realTime" class="col-sm-4 col-form-label">Result Time</label>
                                        <div class="col-sm-8">
                                            <input type="time" name="market_sunday_time_open" value={formData.market_sunday_time_open} onChange={handleInputChange} class="form-control" id="realTime" placeholder="" required />
                                        </div>
                                    </div>
                                    <div class="form-group row pt-3">
                                        <div class="col-sm-8">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                )
            }

            {
                (isAddResult)
                && (

                    <div className='addGames_result' style={{ display: 'block' }}>
                        <div className="">
                            <div className="card-header">
                                <div className="col-12">
                                    <div className="row d-flex">
                                        <div className="col-6">
                                            <span className="badge bg-dark">{description}</span>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-dark float-end" onClick={handleBackClick}>
                                                <FontAwesomeIcon icon="arrow-left" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="p-3">
                                <form onSubmit={addResultSubmit}>
                                    <input type="text" name='market_name' value={formData.market_name} onChange={handleInputChange} className='addSubmitValue form-control' placeholder='Result' disabled />
                                    <div class="form-group row pt-3">
                                        <label for="result" class="col-sm-4 col-form-label">Result</label>
                                        <div class="col-sm-8">
                                            <input type="number" name='result' value={formData.result} onChange={handleInputChange} className='form-control' placeholder='Result' required />
                                        </div>
                                    </div>
                                    <div class="form-group row pt-3">
                                        <label for="marketName" class="col-sm-4 col-form-label">Date</label>
                                        <div class="col-sm-8">
                                            <input type="date" name='curr_date' max={new Date().toISOString().split('T')[0]} value={formData.curr_date} onChange={handleInputChange} className='form-control' required />
                                        </div>
                                    </div>
                                    <div class="form-group row pt-3">
                                        <div class="col-sm-8">
                                            <button type="submit" className="btn btn-primary" >Submit</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >

    )
}

export default Games;