import { library } from '@fortawesome/fontawesome-svg-core';
import { faTable, faTrashAlt, faEdit, faEye, faToggleOn, faToggleOff, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, json, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, createContext } from 'react';
import DeleteConfirmaModal from './deletModalBox';


library.add(faTable, faTrashAlt, faEdit, faEye, faToggleOn, faToggleOff, faArrowLeft, faArrowRight);

function Gussing() {
    const location = useLocation(); // Use the useLocation hook
    const isEditPage = location.pathname.includes('/admin/gussing/edit');
    const isViewPage = location.pathname.includes('/admin/gussing/view');
    const isAddPage = location.pathname.includes('/admin/gussing/add');

    const [selectedRow, setSelectedRow] = useState(null);
    const [guessingDetail, guessingUpdate] = useState([]);
    // add, edit delet view gussing
    const [selectedOption, setSelectedOption] = useState(null);
    const [description, setDescription] = useState('Add Description');
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);


    const handleRowClick = (row) => {
        setSelectedRow(row);
    };


    const handleDelete = (id) => {
        setCurrentId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentId(null);
    };




    const handleConfirmDelete = () => {
        const id = currentId;
        const deleteData = guessingDetail.find(item => item.id == id)
        fetch('https://api.sattakingvip.co.in/updateguessing', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...deleteData, isDeleted: true }),
        })
            .then(response => response.json())
            .then(json => {
                guessingUpdate(json)

            })
            .catch(error => console.error(error));
        const updatedData = guessingDetail.filter(item => item.id !== id);
        guessingUpdate(updatedData);
        handleCloseModal();
    };



    const [sendList, sndList] = useState([]);
    useEffect(() => {

        fetch('https://api.sattakingvip.co.in/getGameName', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(json => {
                sndList(json)
            })
            .catch(error => console.error(error));
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        gameName: '',
        gussNumber: '',
    });
    const handleGameChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    useEffect(() => {
        const currentUrl = window.location;
        if (currentUrl.pathname.includes('/admin/gussing/edit')) {
            setIsFormDisabled(false);
            setDescription('Edit Description');
        } else if (currentUrl.pathname.includes('/admin/gussing/view')) {
            setIsFormDisabled(true);
            setDescription('View Description');
        } else {
            setIsFormDisabled(false);
            setSelectedRow(null)
            setDescription('Add Description');
        }
    }, [location.pathname]);

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };



    useEffect(() => {
        fetch('https://api.sattakingvip.co.in/updateguessing', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(json => {
                guessingUpdate(json)
            })
            .catch(error => console.error(error));
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (selectedRow) {
            let changeData = JSON.parse(JSON.stringify(selectedRow))
            changeData[name] = value
            setSelectedRow(changeData)
        }
        else setSelectedRow({ [name]: value })

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        if (selectedRow) {
            selectedRow.name = selectedRow.name
            selectedRow.gameName = selectedRow.market_name
            selectedRow.gussNumber = selectedRow.gusser_num
        } else {

            setSelectedRow({
                name: '',
                gameName: '',
                gussNumber: ''
            })
        }
        if (guessingDetail.find(x => x.name == selectedRow.name && x.market_name == selectedRow.gameName && x.gusser_num == selectedRow.gussNumber)) {
            return alert('data already exist')
        }
        let isAdd = false
        let isEdit = false
        if (isAddPage) {
            isAdd = true
        }
        if (isEditPage) {
            isEdit = true
        }
        fetch('https://api.sattakingvip.co.in/updateguessing', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...selectedRow, addPage: isAdd, editPage: isEdit }),
        })
            .then(response => response.json())
            .then(json => {
                guessingUpdate(json)

            })
            .catch(error => console.error(error));
        event.preventDefault();
        navigate(-1);

    };

    const [toggleStates, setToggleStates] = useState(guessingDetail.reduce((acc, row) => ({ ...acc, [row.id]: false }), {}));
    const handleToggle = (id) => {
        setToggleStates((prevStates) => ({ ...prevStates, [id]: !prevStates[id] }));
    };
    return (
        <div className="card mt-5">
            {
                (!(isEditPage || isViewPage || isAddPage)) &&
                (
                    <div>
                        <div className="card-header">
                            <div className="col-12">
                                <div className="row d-flex">
                                    <div className="col-6">
                                        {/* <span className="badge bg-dark"><FontAwesomeIcon className='fonticon' icon="table" />Gussing</span> */}
                                        <span className="badge bg-dark position-relative">
                                            <FontAwesomeIcon className='fonticon' icon="table" />Gussing
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {guessingDetail.length}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-primary float-end">
                                            <Link to="/admin/gussing/add" className='nav-link'>Add Guessing</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='gussing table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col-3">Sr. No</th>
                                        <th scope="col-3">Guesser Name</th>
                                        <th scope="col-2">Game Name</th>
                                        <th scope="col-3">Guessing Number</th>
                                        <th scope="col-3">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {guessingDetail.map((row, index) => (
                                        <tr key={row.id}>
                                            <td>{index + 1}</td>
                                            <td>{row.name}</td>
                                            <td>{row.market_name}</td>
                                            <td>{row.gusser_num}</td>
                                            <td className=''>
                                                <FontAwesomeIcon key={row.id} className='form-switch-on' id={row.id} icon={toggleStates[row.id] ? faToggleOff : faToggleOn} size="1x" onClick={() => handleToggle(row.id)} style={{ cursor: 'pointer' }} />
                                                <FontAwesomeIcon icon="trash-alt" style={{ cursor: 'pointer' }} className='form-switch ' onClick={() => handleDelete(row.id)} />
                                                <Link to="/admin/gussing/edit" className='nav-link form-switch'><FontAwesomeIcon size="1x" icon="edit" onClick={() => handleRowClick(row)} /></Link>
                                                <Link to="/admin/gussing/view" className='nav-link form-switch'><FontAwesomeIcon size="1x" icon="eye" onClick={() => handleRowClick(row)} /></Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <DeleteConfirmaModal
                                    show={showModal}
                                    handleClose={handleCloseModal}
                                    handleConfirm={handleConfirmDelete} />
                            </table>
                        </div>
                    </div>
                )}

            {
                (isEditPage || isViewPage || isAddPage)
                && (
                    <div className='addGussing' style={{ display: 'block' }}>
                        <div className="">
                            <div className="card-header">
                                <div className="col-12">
                                    <div className="row d-flex">
                                        <div className="col-6">
                                            <span className="badge bg-dark">{description}</span>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-dark float-end" onClick={handleBackClick}>
                                                <FontAwesomeIcon icon="arrow-left" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="p-3">
                                <form id="xyz" className="align-items-center" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label className="form-label">Name</label>
                                        <input type="text" name="name" value={selectedRow?.name} onChange={handleChange} className="form-control" required disabled={isFormDisabled} />
                                    </div>
                                    <div className="form-group pt-4">
                                        <label className="form-label">Game Name</label>

                                        <select
                                            name="market_name"
                                            onChange={handleChange}
                                            value={selectedRow?.market_name}
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="dropdown"
                                            required
                                            disabled={isFormDisabled} // replace this with your `isFormDisabled` state
                                        >
                                            <option></option>
                                            {sendList?.map((game) => (
                                                <option name='gameName' key={game.id} value={game.value}>
                                                    {game.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group pt-4">
                                        <label className="form-label">Gussing Number</label>
                                        <input type="number" required disabled={isFormDisabled} value={selectedRow?.gusser_num} onChange={handleChange} className="form-control" name="gusser_num" />
                                    </div>
                                    {
                                        !(isViewPage) && (
                                            <div className="form-group pt-4" style={{ display: 'block' }}>
                                                {/* <button type="submit" className="btn btn-info" onSubmit={handleSubmit}>Post</button> */}
                                                <button type="submit" className="btn btn-info">Post</button>
                                            </div>
                                        )

                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default Gussing;