import { library } from '@fortawesome/fontawesome-svg-core';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, json, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteConfirmaModal from './deletModalBox';
// library.add(faTable);

const toolbarOptions = [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ['clean'], // remove formatting button
    ['copy', 'paste', 'undo', 'redo'] // custom buttons
];

const modules = {
    toolbar: {
        container: toolbarOptions,
        handlers: {
            'copy': () => document.execCommand('true'),
            'paste': () => document.execCommand('paste'),
            'undo': () => document.execCommand('undo'),
            'redo': () => document.execCommand('redo'),
        }
    }
};

function Video() {
    const [videoDetail, videoUpdate] = useState([]);
    const [description, setDescription] = useState('Add Video');
    const [formData, setFormData] = useState({});



    const [showModal, setShowModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);



    const location = useLocation(); // Use the useLocation hook
    const isEditPage = location.pathname.includes('/admin/video/edit');
    const isAddPage = location.pathname.includes('/admin/video/add');
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };







    useEffect(() => {
        const currentUrl = window.location;
        if (currentUrl.pathname.includes('/admin/video/edit')) {
            setDescription('Edit Video');
        } else {
            setFormData({})
            setDescription('Add Video')
        }
    }, [location.pathname]);

    useEffect(() => {
        fetch('https://api.sattakingvip.co.in/videoUpdate', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(json => {
                videoUpdate(json)


            })
            .catch(error => console.error(error));
    }, []);
    const handleChange = (content, delta, source, editor) => {

        setFormData({
            ...formData,
            description: content
        });
    };

    // Handle input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle submit button click
    const handleVideoUpload = (event) => {



        let isAdd = false
        let isEdit = false
        if (isAddPage) {
            isAdd = true
            if (videoDetail.find(x => x.link_code == formData.link_code && x.title == formData.title)) {
                return alert('data already exist')
            }
        }
        if (isEditPage) {
            isEdit = true
        }

        fetch('https://api.sattakingvip.co.in/videoUpdate', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...formData, addPage: isAdd, editPage: isEdit }),
        })
            .then(response => response.json())
            .then(json => {
                videoUpdate(json)

            })
            .catch(error => console.error(error));
        // You can now send formData to a server or process it as needed
        event.preventDefault();
        handleBackClick()
    };
    // handale row click edit
    const handleRowClick = (row) => {
        setFormData(row);

        // to add id in url for future use
        // const id = row.id;
        // const newUrl = `${window.location.pathname}?id=${id}`;
        // window.history.pushState(null,newUrl);


        // const params = new URLSearchParams(window.location.search);
        // const value = params.get(row.id);
    };
    // handle delete


    const handleDelete = (id) => {
        setCurrentId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentId(null);
    };
    const handleConfirmDelete = () => {
        const id = currentId;
        const deleteData = videoDetail.find(item => item.id === id);

        fetch('https://api.sattakingvip.co.in/videoUpdate', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify({ ...deleteData, isDeleted: true }),
        })
            .then(response => response.json())
            .then(json => {
                videoUpdate(json);
            })
            .catch(error => console.error(error));

        const updatedData = videoDetail.filter(item => item.id !== id);
        videoUpdate(updatedData);

        handleCloseModal();
    };

    return (
        <div className="card mt-5">
            {(!(isEditPage || isAddPage)) &&
                (
                    <div>
                        <div className="card-header">
                            <div className="col-12">
                                <div className="row d-flex" >
                                    <div className="col-6">
                                        {/* <span className="badge bg-dark"><FontAwesomeIcon className='fonticon' icon="table" />Video</span> */}

                                        <span className="badge bg-dark position-relative">
                                            <FontAwesomeIcon className='fonticon' icon="table" />Video
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {videoDetail.length}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-primary float-end">
                                            <Link to="/admin/video/add" className='nav-link'>Add Video</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='video table-responsive' id="scrollbar1">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col-1">S.NO.</th>
                                        <th scope="col-1">Video</th>
                                        <th scope="col-2">Link</th>
                                        <th scope="col-4">Description</th>
                                        <th scope="col-2">Title</th>
                                        <th scope="col-2">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {videoDetail.map((row, index) => (
                                        <tr key={row.id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div className="embed-responsive embed-responsive-1by1">
                                                    <iframe className="embed-responsive-item" src={row.link_code}></iframe>
                                                </div>
                                            </td>
                                            <td>{row.link_code}</td>
                                            <td className='desc'>{row.description}</td>
                                            <td>{row.title}</td>
                                            <td>
                                                <FontAwesomeIcon
                                                    icon="trash-alt"
                                                    style={{ cursor: 'pointer' }}
                                                    className="form-switch"
                                                    onClick={() => handleDelete(row.id)}
                                                />
                                                <Link to="/admin/video/edit" className="nav-link form-switch">
                                                    <FontAwesomeIcon size="1x" icon="edit" onClick={() => handleRowClick(row)} />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                                <DeleteConfirmaModal
                                    show={showModal}
                                    handleClose={handleCloseModal}
                                    handleConfirm={handleConfirmDelete}
                                />
                            </table>
                        </div>
                    </div>
                )
            }

            {
                (isEditPage || isAddPage)
                && (
                    <div className='addVideo' style={{ display: 'block' }}>
                        <div className="card-header">
                            <div className="col-12">
                                <div className="row d-flex">
                                    <div className="col-6">
                                        <span className="badge bg-dark">{description}</span>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-dark float-end">
                                            <FontAwesomeIcon icon="arrow-left" onClick={handleBackClick} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-3">
                            <form className="align-items-center needs-validation" onSubmit={handleVideoUpload} novalidate>
                                <div className="form-group">
                                    <input type='link' name='link_code' value={formData?.link_code} placeholder='Link code here' className="form-control mt-5" onChange={handleInputChange} required />
                                    <input type='text' name='title' value={formData?.title} placeholder='Title here' className="form-control mt-5" onChange={handleInputChange} required />
                                </div>
                                <div>
                                </div>

                                <div className=''>
                                    <ReactQuill value={formData.description} onChange={handleChange} modules={modules} className="mt-5" />
                                </div>
                                <label htmlFor="videoUpload" style={{ display: 'block', marginTop: '10px' }}>
                                    <button type="submit" className='btn btn-primary' >Upload Video</button>
                                </label>
                            </form>
                        </div>


                    </div>
                )
            }
        </div >

    )
}

export default Video;