import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import moment from "moment";

const momenttz = require('moment-timezone')
function Dashboard({ gameData, gussing, advetise, video, result, newVisitor, allVisitor }) {


    var currentDate = moment().tz('Asia/Kolkata').format("YYYY-MM-DD");
   
    return (
        <div className="dashboard mt-4 pb-2">
            <h1>Dashboard</h1>
            <div className="row gy-5">
                <div className="col-md-4 col-sm-6">
                    <Link to="/admin/visitors" className='nav-link'><div className="p-3 border bg-success text-light"> <p>{allVisitor?.length} </p> <p>All visitors</p></div></Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link to="/admin/visitors" className='nav-link'><div className="p-3 border bg-success text-light"><p>{newVisitor.length}</p> <p>New visitors</p></div></Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link to="/admin/video" className='nav-link'><div className="p-3 border bg-success text-light"><p>{video}</p> <p>video</p></div></Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link to="/admin/advertisements" className='nav-link'><div className="p-3 border bg-success text-light"><p>{advetise} </p> <p>Adevertisements</p></div></Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link to="/admin/games" className='nav-link'><div className="p-3 border bg-success text-light"><p>{gameData}</p> <p>Games</p></div></Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link to="/admin/results" className='nav-link'><div className="p-3 border bg-success text-light"><p>{result}</p> <p>Result</p></div></Link>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Link to="/admin/gussing" className='nav-link'><div className="p-3 border bg-success text-light"><p>{gussing}</p> <p>Gussing</p></div></Link>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;