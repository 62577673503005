import React, { useEffect, useRef } from 'react';

const DeleteConfirmaModal = ({ show, handleClose, handleConfirm }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (show) {
      modalRef.current.style.display = 'block';
      setTimeout(() => {
        modalRef.current.classList.add('show');
      }, 0);
    } else {
      modalRef.current.classList.remove('show');
      setTimeout(() => {
        modalRef.current.style.display = 'none';
      }, 300); // Match the Bootstrap modal transition duration
    }
  }, [show]);
  return (
    <div
      ref={modalRef}
      className="modal fade"
      tabIndex="-1"
      style={{ display: 'none' }}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Deletion</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleConfirm}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmaModal;
