import { library } from '@fortawesome/fontawesome-svg-core';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, json, useNavigate } from 'react-router-dom';
import moment from 'moment';
const momenttz = require('moment-timezone')
library.add(faTable);





function Results() {
    const location = useLocation();
    var currentDate = moment(new Date).tz('Asia/Kolkata').format("YYYY-MM-DD")
    var prevDate = moment(new Date).tz('Asia/Kolkata').subtract(1, 'days').format("YYYY-MM-DD")
    const isEditPage = location.pathname.includes('/admin/results/edit');
    const isViewPage = location.pathname.includes('/admin/results/view');
    const [description, setDescription] = useState('Edit Result');
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [resultRow, setResultRow] = useState()
    const getApiData = async () => {
        await fetch('https://api.sattakingvip.co.in/getData', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify(
                {
                    game_name: "",
                    curr_date: currentDate,
                    prev_date: prevDate,
                    open_time: "market_sunday_time_open"
                }
            ),
        })
            .then(response => response.json())
            .then(json => setResultRow(json))
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getApiData()
    }, []);


    const handleRowEditView = (row) => {
        setResultRow(row);
    };


    useEffect(() => {
        if (isEditPage) {
            setIsFormDisabled(false);
            setDescription('Edit Result');
        } else {
            setIsFormDisabled(true);
            setDescription('View Result');
        }
    }, [location.pathname]);

    const navigate = useNavigate();
    const handleBackClick = async () => {
        await getApiData()
        navigate(-1);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let obj
        if (name == 'result') {
            obj = {
                curr_date: typeof (resultRow.curr_date) == 'object' ? resultRow.curr_date.date : resultRow.curr_date,
                game_name: resultRow.game_name,
                [name]: value
            }
            setResultRow(obj);
        } else {
            setResultRow({
                ...resultRow.curr_date,
                [name]: value
            });
        }
    };

    const resultSubmit = (async (event) => {
        event.preventDefault();
        const editResultObj = {
            market_name: resultRow.game_name,
            curr_date: resultRow.curr_date,
            result: resultRow.result,
            isEditPage: isEditPage
        }


        fetch('http://localhost:3001/resultUpdate', {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify(editResultObj),
        }).catch(error => console.error(error));
        await getApiData()
        handleBackClick()
    });



    return (
        <div className="card mt-5">
            {
                (!(isEditPage || isViewPage)) && (
                    <div >

                        <div className="card-header">
                            <div className="col-12">
                                <div className="row d-flex" >
                                    <div className="col-6">
                                        <span className="badge bg-dark"><FontAwesomeIcon className='fonticon' icon="table" />Result</span>
                                    </div>
                                    <div className="col-6">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='result table-responsive' id="scrollbar1">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col-2">S.NO.</th>
                                        <th scope="col-2">Market Name</th>
                                        <th scope="col-2">Old Result</th>
                                        <th scope="col-2">New Result</th>
                                        <th scope="col-2">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resultRow?.map((gameResult, index) =>
                                        <tr key={gameResult?.id}>
                                            <td>{index + 1}</td>
                                            <td className=''>{gameResult?.game_name}</td>
                                            <td className=''>{gameResult?.prev_date?.result || 'NULL'}</td>
                                            <td className=''>{gameResult?.curr_date?.result || 'NULL'}</td>
                                            <td>
                                                <Link to="/admin/results/edit" className='nav-link form-switch'><FontAwesomeIcon size="1x" icon="edit" onClick={() => handleRowEditView(gameResult)} /></Link>
                                                <Link to="/admin/results/view" className='nav-link form-switch'><FontAwesomeIcon size="1x" icon="eye" onClick={() => handleRowEditView(gameResult)} /></Link>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                )
            }


            {
                (isEditPage || isViewPage) && (

                    <div className='result' style={{ display: 'block' }}>
                        <div className="">
                            <div className="card-header">
                                <div className="col-12">
                                    <div className="row d-flex">
                                        <div className="col-6">
                                            <span className="badge bg-dark">{description}</span>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-dark float-end" onClick={handleBackClick}>
                                                <FontAwesomeIcon icon="arrow-left" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-3">
                                <form className="align-items-center" onSubmit={resultSubmit}>
                                    <div className="form-group">
                                        <label className="form-label">Market Name</label>
                                        <input type="text" name="game_name" value={resultRow?.game_name} className="form-control" onChange={handleInputChange} required disabled />
                                    </div>
                                    <div className="form-group pt-4">
                                        <label className="form-label">Result</label>
                                        <input type="text" name="result" value={resultRow?.curr_date?.result} className="form-control" onChange={handleInputChange} required disabled={isFormDisabled} />
                                    </div>
                                    {
                                        !(isViewPage) && (
                                            <div className="form-group pt-4" style={{ display: 'block' }}>
                                                <button type="submit" className="btn btn-info" >Post</button>
                                            </div>
                                        )
                                    }
                                </form>
                            </div>
                        </div>
                    </div>

                )
            }




        </div >
    )
}

export default Results;